<template>
    <div class="inner-section">
      <card>
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ $t('tpm_report.training_course_summary_report') }} {{ $t('globalTrans.search') }}</h4>
        </template>
        <template v-slot:searchBody>
          <b-row>
            <b-col lg="6" sm="6">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="fiscal_year_id"
              >
                <template v-slot:label>
                  {{$t('elearning_config.fiscal_year')}}
                </template>
                <b-form-select
                    plain
                    v-model="search.fiscal_year_id"
                    :options="fiscalYearList"
                    id="fiscal_year_id"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col lg="6" sm="12">
                <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('elearning_config.training_quarter_name')"
                label-for="training_quarter_name"
                >
                <b-form-select
                  plain
                  v-model="search.training_quarter_id"
                  :options="trainingQuarterSetupList"
                  id="training_quarter_id"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
                </b-form-group>
            </b-col>
            <b-col lg="6" sm="6">
              <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="circular_memo_no"
                >
                  <template v-slot:label>
                    {{$t('elearning_iabm.circular_memo_no')}}
                  </template>
                  <b-form-input
                      id="circular_memo_no"
                      v-model="search.circular_memo_no"
                  ></b-form-input>
                </b-form-group>
            </b-col>
            <div class="col-6">
              <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="start_date"
              >
                <template v-slot:label>
                  {{$t('globalTrans.start_date')}}
                </template>
                <b-form-input
                    class="fromDate"
                    v-model="search.start_date"
                    :placeholder="$t('globalTrans.select_date')"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-6">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="end_date"
                >
                  <template v-slot:label>
                    {{$t('globalTrans.end_date')}}
                  </template>
                  <b-form-input
                      class="fromDate"
                      v-model="search.end_date"
                      :placeholder="$t('globalTrans.select_date')"
                  ></b-form-input>
                </b-form-group>
            </div>
            <b-col>
              <!-- <b-button type="submit" variant="primary" class="mr-2"  @click="searchData">{{ $t('globalTrans.search') }}</b-button> -->
              <b-button size="sm" variant="primary" class="" @click="searchData">
                <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
              </b-button>
            </b-col>
            </b-row>
        </template>
      </card>
      <b-row>
        <b-col md="12" v-if="showData">
          <b-overlay>
            <b-row v-if='datas.length > 0'>
              <b-col md="12">
                <body-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">{{ $t('tpm_report.training_course_summary_report') }}</h4>
                  </template>
                  <template v-slot:headerAction>
                    <b-button class="btn_add_new" @click="pdfExport">
                      <i class="fas fa-print"></i>{{ $t('globalTrans.print') }}
                    </b-button>
                  </template>
                  <template v-slot:body>
                    <b-overlay>
                      <b-row mt-5>
                        <b-col md="12" class="table-responsive">
                          <div style="border: 2px solid;margin:10px;padding:10px">
                            <b-row>
                              <b-col>
                                <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                                  <template v-slot:projectNameSlot>
                                    {{ }}
                                  </template>
                                  {{ $t('tpm_report.training_course_summary_report') }}
                                </list-report-head>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <div class="text-center">
                                  <table style="width:100%;color:black;">
                                    <tr v-if="search.fiscal_year">
                                        <td align="right" style="width:45%">{{ $t('elearning_config.fiscal_year') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.fiscal_year_bn : search.fiscal_year }}</td>
                                    </tr>
                                    <tr v-if="search.training_quarter_id">
                                        <td align="right" style="width:45%">{{ $t('elearning_config.training_quarter_name') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.quaeter_name_bn : search.quaeter_name }}</td>
                                    </tr>
                                    <tr v-if="search.circular_memo_no">
                                        <td align="right" style="width:45%">{{ $t('elearning_iabm.circular_memo_no') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:50%">{{ search.circular_memo_no }}</td>
                                    </tr>
                                    <tr v-if="search.course_id">
                                        <td align="right" style="width:45%">{{ $t('globalTrans.course') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.course_name_bn : search.course_name }}</td>
                                    </tr>
                                    <tr v-if="search.start_date">
                                        <td align="right" style="width:45%">{{ $t('globalTrans.start_date') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:50%">{{ search.start_date | dateFormat }}</td>
                                    </tr>
                                    <tr v-if="search.end_date">
                                        <td align="right" style="width:45%">{{ $t('globalTrans.end_date') }}</td>
                                        <td align="center" style="width:5%">:</td>
                                        <td align="left" style="width:50%">{{ search.end_date | dateFormat }}</td>
                                    </tr>
                                  </table>
                                </div>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <b-table-simple class="tg mt-3" bordered hover small caption-top responsive v-if="datas.length > 0">
                                  <b-thead>
                                    <b-tr>
                                      <b-th style="width:8%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                      <b-th style="width:10%" class="text-center">{{ $t('elearning_iabm.circular_memo_no') }}</b-th>
                                      <b-th style="width:12%" class="text-center">{{ $t('elearning_config.fiscal_year') }}</b-th>
                                      <b-th style="width:13%" class="text-center">{{ $t('globalTrans.organization') }}</b-th>
                                      <b-th class="text-center">{{$t('elearning_config.training_title')}}</b-th>
                                      <b-th class="text-center">{{$t('tpm_report.speaker_communication')}}</b-th>
                                      <b-th class="text-center">{{$t('tpm_report.course_name')}}</b-th>
                                      <b-th style="width:11%" class="text-center">{{$t('tpm_report.total_lession')}}</b-th>
                                    </b-tr>
                                  </b-thead>
                                  <b-tbody v-for="(info, index) in datas" :key="index">
                                    <b-td class="text-center">{{ $n(index + 1, { useGrouping: false }) }}</b-td>
                                    <b-td class="text-center">{{ info.circular_memo_no }}</b-td>
                                    <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.fiscal_year_bn : info.fiscal_year }}</b-td>
                                    <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.org_bn : info.org }}</b-td>
                                    <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.training_title_bn : info.training_title }}</b-td>
                                    <b-td class="text-center">{{ (($i18n.locale==='bn') ? info.name_bn : info.name) + ', ' + (($i18n.locale==='bn') ? info.designation_bn : info.designation)
                                      + ', ' + ($i18n.locale === 'bn' ? '০' : '0') + $n(info.mobile, { useGrouping: false }) }}</b-td>
                                    <!-- <b-td class="text-center">
                                      <span v-for="(lesson, index1) in info.lessons" :key="index1">
                                          {{ ($i18n.locale==='bn') ? lesson.topics_session_bn : lesson.topics_session + ', '}}
                                      </span>
                                    </b-td> -->
                                    <b-td class="text-center">
                                      {{ getLessonName(info.lessons) }}
                                    </b-td>
                                    <b-td class="text-center">
                                      {{ $n(info.lessons.length) }}
                                    </b-td>
                                  </b-tbody>
                                </b-table-simple>
                              </b-col>
                            </b-row>
                          </div>
                        </b-col>
                      </b-row>
                    </b-overlay>
                  </template>
                </body-card>
              </b-col>
            </b-row>
            <div class="panel-body text-center mt-3" v-else>
              <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
            </div>
          </b-overlay>
        </b-col>
      </b-row>
    </div>
</template>
<script>

import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingCourseSummaryReport } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
    props: ['id'],
    components: {
        ListReportHead
    },
    data () {
        return {
          saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
          search: {
            course_id: 0,
            training_quarter_id: 0,
            circular_no: '',
            fiscal_year_id: 0,
            training_type: '',
            start_date: '',
            end_date: ''
          },
          officeTypeList: [],
          officeList: [],
          trainingCategoryList: [],
          trainingTitleList: [],
          courseList: [],
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          datas: [],
          loading: false,
          showData: false,
          currentFiscalYearId: 0
        }
    },
    created () {
      this.search.fiscal_year_id = this.$store.state.currentFiscalYearId
    },
    mounted () {
    flatpickr('.fromDate', {})
    },
    computed: {
      trainingQuarterSetupList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingQuarterSetupList.filter(item => item.status === 1)
      },
      fiscalYearList: function () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
      },
      i18 () {
        return this.$i18n.locale
      }
    },
    watch: {
      'search.circular_memo_no': function (newValue) {
        this.courseList = this.getCourseList(newValue)
      }
    },
    methods: {
      pdfExport () {
          const reportTitle = this.$t('tpm_report.training_course_summary_report')
          ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.datas, this, this.search)
      },
      onChangeFile (e) {
          this.attachmentDemo = e.target.files[0]
      },
      getCourseList (circularMemoNo) {
        const courseList = this.$store.state.TrainingElearning.commonObj.courseList
        if (circularMemoNo) {
          return courseList.filter(item => item.circular_memo_no === String(circularMemoNo))
        }
        return courseList
      },
      getEditingData () {
          const tmpData = this.$store.state.list.find(item => item.id === this.id)
          return JSON.parse(JSON.stringify(tmpData))
      },
      async searchData () {
        this.loading = true
        this.showData = true
        this.getCustomDataSearch()
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainingCourseSummaryReport, this.search)
        if (result.success) {
          this.loading = false
          if (result.data) {
            this.datas = this.getCustomDataList(result.data)
          }
        } else {
          this.datas = []
          this.loading = false
        }
      },
      getCustomDataSearch () {
        const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(fiscalYearList => fiscalYearList.value === parseInt(this.search.fiscal_year_id))
        if (typeof fiscalYearObj !== 'undefined') {
          this.search.fiscal_year = fiscalYearObj.text_en
          this.search.fiscal_year_bn = fiscalYearObj.text_bn
        //   this.search.fiscal_year_bn = fiscalYearObj.text_bn
        } else {
          this.search.fiscal_year = ''
          this.search.fiscal_year_bn = ''
        }
        const tQuarterObj = this.$store.state.TrainingElearning.commonObj.trainingQuarterSetupList.find(doc => doc.value === parseInt(this.search.training_quarter_id))
        if (typeof tQuarterObj !== 'undefined') {
            this.search.quaeter_name = tQuarterObj.text_en
            this.search.quaeter_name_bn = tQuarterObj.text_bn
        } else {
            this.search.quaeter_name = ''
            this.search.quaeter_name_bn = ''
        }
        const courseObj = this.$store.state.TrainingElearning.commonObj.courseList.find(doc => doc.value === parseInt(this.search.course_id))
        if (typeof courseObj !== 'undefined') {
            this.search.course_name = courseObj.text_en
            this.search.course_name_bn = courseObj.text_bn
        } else {
            this.search.course_name = ''
            this.search.course_name_bn = ''
        }
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const orgObj = this.$store.state.CommonService.commonObj.orgProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const officeObj = this.$store.state.CommonService.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const trainerObj = this.$store.state.TrainingElearning.commonObj.personalInfoList.find(doc => doc.value === item.trainer_id)
          const trainerData = {}
          if (typeof trainerObj !== 'undefined') {
            trainerData.trainer = trainerObj.text_en
            trainerData.trainer_bn = trainerObj.text_bn
          } else {
            trainerData.trainer = ''
            trainerData.trainer_bn = ''
          }

          const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const designationObj = this.$store.state.CommonService.commonObj.designationList.find(doc => doc.value === parseInt(item.designation_id))
          const designationData = {}
          if (typeof designationObj !== 'undefined') {
            designationData.designation = designationObj.text_en
            designationData.designation_bn = designationObj.text_bn
          } else {
            designationData.designation = ''
            designationData.designation_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.CommonService.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }

          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          return Object.assign({}, item, orgData, officeData, fiscalYearData, designationData, OfficeTypeData, trainingTitleData, trainingTypeData, trainingCategoryData, trainerData)
        })
        return listData
      },
      getLessonName (datas) {
        const lesson = []
        datas.map((item, index) => {
          if (this.$i18n.locale === 'bn') {
            lesson.push(item.topics_session_bn)
          } else {
            lesson.push(item.topics_session)
          }
        })
        return lesson.toString()
      }
    }
}
</script>
